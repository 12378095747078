<template>
  <div class="home black--text">
  <v-row dense align="center" justify="space-around" class="text-center">
  <v-col cols="12" xs="12" sm="12" align-self="center" class="d-flex justify-center align-center">
 <v-img
  :src="require('../assets/logo.svg')"
  max-width="300"
  contain
  alt="הצטרפות למועדון החברים של רשת גבעול"
  ></v-img>
  </v-col>
   <v-col cols="12" xs="12" sm="12" align-self="center" class="d-flex justify-center align-center style1 text-h4 font-weight-bold mt-3">
 היי, ראינו שהתקשרת
  </v-col>
  <v-col cols="12" xs="12" sm="12" align-self="center" class="d-flex justify-center align-center style1 text-h5">
באפשרותך לקבל מענה
<br/>
בלחיצת כפתור
  </v-col>
  </v-row>
  <v-row dense align="center" justify="space-around" class="text-center">
  <v-col cols="12" xs="12" sm="12" align-self="center" class="d-flex justify-center align-center">
    <v-btn
  elevation="2"
  tile
  color="#004337"
  block
  dark
  class="btnBorder"
  href="https://api.whatsapp.com/send/?phone=97237482001&text&app_absent=0"
  target="_blank"
>הזמנת משלוח / איסוף עצמי</v-btn>
  </v-col>
  <v-col cols="12" xs="12" sm="12" align-self="center" class="d-flex justify-center align-center">
    <v-btn
  elevation="2"
  tile
  color="#004337"
  block
  dark
  class="btnBorder"
  href="https://api.whatsapp.com/send/?phone=97237482001&text&app_absent=0"
  target="_blank"
>בירור אודות הזמנה קיימת</v-btn>
  </v-col>
  <v-col cols="12" xs="12" sm="12" align-self="center" class="d-flex justify-center align-center">
    <v-btn
  elevation="2"
  tile
  color="#004337"
  block
  dark
  class="btnBorder"
  href="#"
  target="_blank"
>צפייה במלאי עדכני</v-btn>
  </v-col>
  <v-col cols="12" xs="12" sm="12" align-self="center" class="d-flex justify-center align-center">
    <v-btn
  elevation="2"
  tile
  color="#004337"
  block
  dark
  class="btnBorder"
  href="https://api.whatsapp.com/send/?phone=97237482001&text&app_absent=0"
  target="_blank"
><v-icon left>mdi-whatsapp</v-icon> צ'אט עם נציג גבעול</v-btn>
  </v-col>
  <v-col cols="12" xs="12" sm="12" align-self="center" class="d-flex justify-center align-center">
    <v-btn
  elevation="2"
  tile
  color="#004337"
  block
  dark
  class="btnBorder"
  href="https://givolmembers.givol-pharmacy.co.il/"
  target="_blank"
>הצטרפות למועדון חברים</v-btn>
  </v-col>
  </v-row>
   <v-divider class="my-4"/>
   <v-row dense>
    <v-col cols="5">
    <v-row dense align="start" justify="center" class="text-start text-caption">
    <v-col cols="12">
   <h2 class="text-title style1">פרטים ליצירת קשר:</h2>
   </v-col>
   <v-col cols="12" class="text-body-2 mb-0 pb-0" tabindex="12">
   קבלת קהל בימים א' – ה' בין השעות 21:00 – 09:00
    <br/>
במוקד הטלפוני עד השעה 18:00, ובימי שישי קבלת קהל ומענה עד השעה 15:00
   </v-col>
    </v-row>
    </v-col>
    <v-col cols="2" style="text-align:center;">
     <v-divider vertical class="my-2"/>
    </v-col>
    <v-col cols="5">
     <v-row dense align="start" justify="center" class="text-start text-caption">
      <v-col cols="12">
   <h2 class="text-title style1">כתובות סניפי בית מרקחת גבעול:</h2>
   </v-col>
    <v-col cols="12" class="text-body-2 mt-0 pt-0">
יפו 216, ירושלים | מונטיפיורי 1, באר שבע | חבצלת השרון 36, הרצליה | הירקון 288, ת"א | גיא אוני 12, אשדוד | רבי שמעון בר יוחאי 6, דימונה
   </v-col>
     </v-row>
    </v-col>
   <!-- <v-col cols="12">
   * ההטבה כפופה למימוש כל 5 המרשמים הקודמים ברציפות ובמלואם ברשת בתי המרקחת גבעול / משלוח.
   </v-col> -->
   </v-row>
   <v-divider class="my-4"/>
   <v-row dense align="center" justify="center" class="text-center text-caption">
    <v-col cols="12" class="text-caption">
אין במידע זה תחליף להיוועצות עם רופא או רוקח בטרם רכישת תכשיר רפואי והתחלת הטיפול בו. <br/>
יש לעיין בעלון לצרכן לפני השימוש בתכשיר הרפואי. <br/>
מומלץ להתייעץ עם הרוקח בכל הנוגע למטרות ואופן השימוש, תופעות לוואי, אינטראקציה עם תכשירים רפואיים אחרים. <br/>
להתייעצות עם רוקח פנו למספר הטלפון 03-7482000 ובנוסף באמצעות דואר אלקטרוני בכתובת Info@givol-pharmacy.co.il
   </v-col>
   </v-row>
  </div>
</template>

<script>
// import { bus } from './../main'
// import { mask } from 'vue-the-mask'
// @ is an alias to /src
export default {
  data () {
    return {
      Dob: null,
      firstName: null,
      lastName: null,
      clientId: null,
      Email: null,
      clientMobile: null,
      gender: null,
      marketing: false,
      roles: false,
      genders: ['זכר', 'נקבה'],
      date: null,
      dateFormatted: null,
      menu: false,
      valid: true,
      showErr: false,
      errTexts: [],
      breadcrumbs: [
        {
          text: 'לוח בקרה',
          disabled: false,
          to: { path: '/' }
        }
      ]
    }
  },
  created () {
    window.Store.commit('Loader', false)
  }
}
</script>

<style>
.theme--light.v-messages {
  color: #000000!important;
}
.theme--light.v-label {
  color: #000000!important;
}
.style1 {
  color: #004337!important;
}
.style2 {
  color: #004337!important;
}
.btnBorder {
  min-height: 60px!important;
  border-style: solid!important;
  border-width: 8px!important;
  border-color: #006753!important;
  font-size: 18px!important;
}
</style>
